// src/authConfig.js

const currentBranch = process.env.REACT_APP_BRANCH;
const environment = process.env.NODE_ENV;
export const msalConfig = {
    auth: {
        clientId: environment === 'development' ?  "766d6eb6-a2e7-418a-9d6e-a629485f0930" : "49e1831c-b97a-4ab8-8f52-19eec5515d2c",
        authority: environment === 'development' ? "https://login.microsoftonline.com/c06e3d2d-07eb-4e00-8812-34632803433d" :  `https://login.microsoftonline.com/cdd27c81-01cf-4287-a0a7-3807229f3498`,
        redirectUri: environment === 'development' ? 'http://localhost:3000/entralogin': currentBranch === 'master' ? 'https://kassan.lrf.se/entralogin' : 'https://kassan-stage.lrf.se/entralogin',
        ValidateAuthority: true,
        postLogoutRedirectUri: environment === 'development' ? 'http://localhost:3000/entralogin': currentBranch === 'master' ? 'https://kassan.lrf.se/entralogin' : 'https://kassan-stage.lrf.se/entralogin',
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "sessionStorage", 
        storeAuthStateInCookie: false,
    }
};

let scope = environment === 'development' ? [`api://b855415d-abec-49d2-b3dd-7c9ad70445eb/API.Access`] : [`api://6a77cbfb-24c7-4fb7-b754-cd569dfd69fc/API.Access`];
export const loginRequest = {
    scopes: scope,
};
