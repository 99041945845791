import {Button, Card, Col, Row} from "reactstrap";
import React, {useState} from "react";
import { CheckUserPermission } from "../../AppSystem/Helpers/CheckUserPermission";
import AdminRequestAttestDenyModel from "../../Attest/Model/Request/AdminRequestAttestDenyModel";
import { CheckUserRole } from "../../AppSystem/Helpers/CheckUserRole";
import { CustomInput } from "../../Common/Components/Input/CustomInput";
import { ToCustomDecimal } from "../../AppSystem/Helpers/ToCustomDecimal";
import { AttestPayViews } from "../../Attest/Components/AttestPayViews";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CustomModal } from "../../Common/Components/Modal/CustomModal";
import { AttestReimbursementViews } from "../../Attest/Components/AttestReimbursementViews";
import { AttestHonorariumViews } from "../../Attest/Components/AttestHonorariumViews";
import { ChangeDateFormat } from "../../Common/Helpers/ChangeDateFormat";
import { CustomButton } from "../../Common/Components/Button/CustomButton";

export function PendingBankTransferView({data, onChange, onValidate, onChangeDenyId, onchangeDenyReason, errorReason, setErrorReason, setShowConfirmModalApprove, showConfirmModalApprove, setShowConfirmModalDeny, showConfirmModalDeny, amount, model}) {
    const { t } = useTranslation();
    const { userData } = useSelector(state => state.auth);

    const [moreInfo, setMoreInfo] = useState(true);
    const isSuperAdmin = CheckUserRole('SuperAdmin'); //SuperAdmin

    let permissionUpdate = false;
    if (CheckUserPermission("attest.approve") || CheckUserPermission("attest")) {
        permissionUpdate = true;
    }

    let permissionDeny = false;
    if (CheckUserPermission("attest.deny") || CheckUserPermission("attest")) {
        permissionDeny = true;
    }

    const contentBodyApproved = (
        <div>{t('validationMessage.modalAttest')}</div>
    );
    const contentFooterApproved = (
        <>
            <Button className="box-green border-0" onClick={async () => {
                setShowConfirmModalApprove(false); // Close Modal
                await onValidate("approve");
            }}>{t('button.accept')}</Button>
            {' '}
            <Button className="box-red border-0" onClick={() => setShowConfirmModalApprove(false)}>{t('button.cancel')}</Button>
        </>
    );

    const contentBodyDeny = (
        <>
            <div className="text-center pb-3">
                <h5>{t('validationMessage.modalAttestDenyHeader')}</h5>
            </div>
            <div className="d-flex justify-content-center">
                <div className="w-75 position-relative">
                    <CustomInput model={AdminRequestAttestDenyModel} attribute="reason" customChange={e => onchangeDenyReason(e)}/>
                    <div className={`error tooltip-custom-normal ${errorReason === true ? 'opacity-1' : 'opacity-0'}`}
                         style={{color: '#f56e6e'}}>
                        {t('validationMessage.fieldRequired')}
                    </div>
                </div>
            </div>
            <div>{t('validationMessage.modalAttestDeny')}</div>
        </>
    );

    const contentFooterDeny = (
        <>
            <Button className="box-green border-0" onClick={async () => {
                setShowConfirmModalApprove(false); // Close Modal
                await onValidate("deny");
            }}>{t('button.accept')}</Button>
            {' '}
            <Button className="box-red border-0" onClick={() => {
                setShowConfirmModalDeny(false);
                setErrorReason(false);
            }}>{t('button.cancel')}</Button>
        </>
    );

    return(
        <>
            <Col lg={12} className="pb-5">
                <Card className="card-backend p-4">
                    <CustomModal isOpen={showConfirmModalApprove} toggle={() => setShowConfirmModalApprove(!showConfirmModalApprove)} body={contentBodyApproved} footer={contentFooterApproved}/>
                    <CustomModal isOpen={showConfirmModalDeny} toggle={() => setShowConfirmModalDeny(!showConfirmModalDeny)} body={contentBodyDeny} footer={contentFooterDeny} />
                    <Row>
                        <Col lg={6}>
                            <h2 className="rokkitt-font">{t('openPayment.infoBankTransfer')} </h2>
                        </Col>
                        <Col lg={6} className="text-end pb-3">
                            {data.createdBy !== userData?.id && data.status === "Skickat" && permissionDeny ?
                                <CustomButton text={t("attest.deny")} iconSize={4} customClasses="rounded me-2 box-red" buttonBorder={false} id={data.id} onClick={() => {
                                    onChangeDenyId(data.id);
                                    setShowConfirmModalDeny(true);
                                }} />
                            : ''}
                            {data.createdBy !== userData?.id && data.status === "Skickat" && permissionUpdate ?
                                <CustomButton text={t('attest.approve')} customClasses="rounded border-0"
                                              onClick ={() => {
                                                  setShowConfirmModalApprove(true);
                                                  onChange();
                                              }}/>
                            : ''}
                        </Col>
                        {data !== null ?
                            <Col lg={12}>
                                <Card className="p-3">
                                    <Row className="px-4">
                                        <Col lg={8}>
                                            <ul className="list-unstyled">
                                                {isSuperAdmin === true ?
                                                    <>
                                                        <li className="pt-2 row">
                                                            <span className="fw-bold col-6 col-lg-3">{t('modelLabel.id')}</span>
                                                            <span className="col-6 col-lg-9">{data.id}</span>
                                                        </li>
                                                        <li className="pt-2 row">
                                                            <span className="fw-bold col-6 col-lg-3">{t('modelLabel.orgId')}</span>
                                                            <span className="col-6 col-lg-9">{data.orgId}</span>
                                                        </li>
                                                    </>
                                                    : ''}
                                                {data.visualId !== "" ?
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-6 col-lg-3">{t('modelLabel.id')}:</span>
                                                        <span className="col-6 col-lg-9">{data.visualId}</span>
                                                    </li>
                                                    : ""}
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-6 col-lg-3">{t('modelLabel.orgName')}</span>
                                                    <span className="col-6 col-lg-9">{data.orgName}</span>
                                                </li>
                                                {data.orgType ?
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-6 col-lg-3">{t('modelLabel.orgType')}</span>
                                                        <span className="col-6 col-lg-9">{data.orgType}</span>
                                                    </li>
                                                : ""}
                                                {data.orgCostPlace ?
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-6 col-lg-3">{t('modelLabel.costPlace')}</span>
                                                        <span className="col-6 col-lg-9">{data.orgCostPlace}</span>
                                                    </li>
                                                : ""}
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-6 col-lg-3">{t('modelLabel.createdAt')}</span>
                                                    <span className="col-6 col-lg-9">{ChangeDateFormat('dateTime', data.createdAt)}</span>
                                                </li>
                                                {isSuperAdmin === true && data.createdBy ?
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-6 col-lg-3">{t('modelLabel.createdBy')}</span>
                                                        <span className="col-9">{data.createdBy}</span>
                                                    </li>
                                                    : ''}
                                                <li className="pt-2 row">
                                                    <span className="fw-bold  col-6 col-lg-3">{t('payReceive.bankAccount')}</span>
                                                    <span className="col-6 col-lg-9">{data.creditorAccountIban}</span>
                                                </li>
                                                {data.clearingNumber && data.clearingNumber !== '' ?
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-6 col-lg-3">{t('payDirectTransmission.number')}</span>
                                                        <span className="col-6 col-lg-9">{data.clearingNumber}</span>
                                                    </li>
                                                    : ''}
                                                {model !== null ?
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold  col-6 col-lg-3">{t('modelLabel.creditorName')}</span>
                                                        <span className="col-6 col-lg-9">{model === 'Skatteverket' ? t('modelLabel.TheSwedishTaxAgency') : data.creditorName}</span>
                                                    </li>
                                                : 
                                                    <li className="pt-2 row">
                                                    <span className="fw-bold  col-6 col-lg-3">{t('modelLabel.creditorName')}</span>
                                                    <span className="col-6 col-lg-9">{data.model === 'Skatteverket' ? t('modelLabel.TheSwedishTaxAgency') : data.creditorName}</span>
                                                    </li>
                                                }
                                                 {model === null ? 
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-6 col-lg-3">{t('modelLabel.type')}</span>
                                                        <span className="col-6 col-lg-9">{model === 'Skatteverket' ? data.type : data.model}</span>
                                                    </li>
                                                : ''}
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-6 col-lg-3">Betalningstyp</span>
                                                    <span className="col-6 col-lg-9">{data.paymentType}</span>
                                                </li>
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-6 col-lg-3">{t('modelLabel.amount')}</span>
                                                    <span className="col-6 col-lg-9">{amount ? ToCustomDecimal(amount) :data.instructedAmount ? ToCustomDecimal(data.instructedAmount) : 0} kr</span>
                                                </li>
                                                <li className="pt-2 row">
                                                    <span className="fw-bold col-6 col-lg-3">{t('modelLabel.status')}</span>
                                                    <span className="col-6 col-lg-9">{data.status}</span>
                                                </li>
                                                {data.attestAmount > 0 && data.approvedBy !== null ?
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-6 col-lg-3">{t('modelLabel.attestAmount')}</span>
                                                        <span className="col-6 col-lg-9">{data.attestAmount ? ToCustomDecimal(data.attestAmount) : 0} kr</span>
                                                    </li>
                                                    : ""}
                                                {data.attestAmount > 0 && data.approvedBy !== null ?
                                                    <li className="pt-2 row">
                                                        <span className="fw-bold col-6 col-lg-3">Attest Skapad</span>
                                                        <span className="col-6 col-lg-9">{ChangeDateFormat('dateTime', data.createdAtAttest)}</span>
                                                    </li>
                                                    : ""}
                                            </ul>

                                        </Col>
                                        {data.approvedBy && data.approvedBy ?
                                            <Col lg={4} className="bg-green-light rounded-2 p-4">
                                                <h5 className='rokkitt-font'>{t('modelLabel.approvedDeniedByName')}</h5>
                                                {data.approvedBy}     
                                                {data.reason ?
                                                    <>
                                                        <h5 className='rokkitt-font mt-4'>{t('modelLabel.reason')}</h5>
                                                        {data.reason}
                                                    </>
                                                : ""}                                         
                                            </Col>
                                        : ''}                                  
                                        {/* {data.model !== "Skatteverket" ? 
                                        <Col lg={12} className="pt-3 pt-lg-0 mb-5">
                                            <CustomButton text={moreInfo === true ? t('button.hideInfo') : t('button.moreInfo') } customClasses="rounded border-0" onClick={() => setMoreInfo(!moreInfo)}/>
                                        </Col>
                                        : ''} */}
                                    </Row>
                                    {moreInfo === true ?
                                        <>
                                            {model !== null && model === "Arvode" ? (
                                                <AttestHonorariumViews data={data.modelInfo} modelId={data.modelId} />
                                            ) : (
                                                data.model === "Arvode" ? (
                                                    <AttestHonorariumViews data={data.modelInfo} modelId={data.modelId} />
                                                ) : null
                                            )}

                                            {model !== null && model === "Förrättning" ? (
                                                <AttestReimbursementViews data={data.modelInfo} modelId={data.modelId}/>
                                            ) : (
                                                data.model === "Förrättning" ? (
                                                    <AttestReimbursementViews data={data.modelInfo} modelId={data.modelId}/>
                                                ) : null
                                            )}
                                            
                                            {model !== null && model === "Betala" ? (
                                                <Row className="px-4 justify-content-center">
                                                    <hr className="mt-4" />
                                                    <AttestPayViews data={data.modelInfo} modelId={data.modelId}/>
                                                </Row>

                                            ) : (
                                                data.model === "Betala" ? (
                                                    <AttestPayViews data={data.modelInfo} modelId={data.modelId}/>
                                                ) : null
                                            )}
                                        </>
                                        : ''}
                                </Card>
                            </Col>
                            : ''}
                    </Row>
                </Card>
            </Col>
        </>
    )
}