import React, {useEffect, useState} from "react";
import useApiCallService from "../../Common/Helpers/ApiCall";
import {AttestViews} from "../Components/AttestViews";
import {Col, Row} from "reactstrap";
import {CustomButton} from "../../Common/Components/Button/CustomButton";
import {ADMIN_PATHS} from "../../config";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import AdminRequestAttestApprovedModel from "../Model/Request/AdminRequestAttestApprovedModel";
import {FlashMessage} from "../../Common/Helpers/FlashMessage";
import AdminResponseAttestModel from "../Model/Response/AdminResponseAttestModel";
import AdminRequestAttestDenyModel from "../Model/Request/AdminRequestAttestDenyModel";
import {CustomReload} from "../../AppSystem/Helpers/CustomReload";
import $ from "jquery";
import {FlashMessageInsufficientAccountAmount} from "../../Common/Helpers/FlashMessageInsufficientAccountAmount";

export function AdminAttestInfo() {
    const { ApiCall } = useApiCallService();
    const idValue = new URLSearchParams(window.location.search).get('id');
    const fromValue = new URLSearchParams(window.location.search).get('from') ?? '';
    const { t } = useTranslation();
    const navigate = useNavigate();

    // API REQUEST
    const [apiRequestAcceptAttest, setApiRequestAcceptAttest] = useState(new AdminRequestAttestApprovedModel());
    const [apiRequestDenyAttest, setApiRequestDenyAttest] = useState(new AdminRequestAttestDenyModel());

    //API RESPONSE
    const [apiResponseAttest, setApiResponseAttest] = useState(new AdminResponseAttestModel());
    const [apiResponseAttest2, setApiResponseAttest2] = useState(null);

    const completeSchemaAccept = AdminRequestAttestApprovedModel.getAttributes( null, true);
    const completeSchemaDeny = AdminRequestAttestDenyModel.getAttributes( null, true);

    const [showConfirmModalApprove, setShowConfirmModalApprove] = useState(false);
    const [showConfirmModalDeny, setShowConfirmModalDeny] = useState(false);

    const [errorReason, setErrorReason] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [type, setType] = useState('');

    useEffect(() => {
        const handleApiResponseAttest  = (apiResponseAttest) => {
            console.log('apiResponseAttest', apiResponseAttest);
            setApiResponseAttest(apiResponseAttest);
        };
        const GetInfoAttest = async () => {
            if (idValue !== null) {
                try {
                    const apiCallOptions = {
                        method: "get",
                        url: `/attest/admin?id=${idValue}`,
                    };
                    await ApiCall(apiCallOptions, handleApiResponseAttest );
                } catch (error) {
                    console.log('error', error);
                }
            }
        };
        GetInfoAttest();
    }, [idValue]);

    // PUT APPROVED
    const handleValidation = async (value) => {
        try {
            setType(value);
            if (value === "approve") {
                await completeSchemaAccept.validate(apiRequestAcceptAttest, { abortEarly: false });
            } else {
                await completeSchemaDeny.validate(apiRequestDenyAttest, { abortEarly: false });
            }
            const apiCallOptions = {
                method: value === "approve" ? "put" : "delete",
                url: "/attest/admin",
                data: value === "approve" ? apiRequestAcceptAttest : apiRequestDenyAttest,
            };
            try {
                await ApiCall(apiCallOptions, setApiResponseAttest2, setErrorMessage);
            } catch (error) {
                console.log(error);
                FlashMessage(t('validationMessage.sendError'), 'flash-messageError');
            }
        } catch (err) {
            setErrorReason(true);
            console.log(err)
        }
    }

    useEffect(() => {
        if (apiResponseAttest2) {
            if (type === "approve") {
                FlashMessage(t('validationMessage.attestApproved'), 'flash-messageSuccess');
                setShowConfirmModalApprove(false);
                $("#btnApproveAttest").removeClass("disabled");
                $(`#${apiResponseAttest.id}`).removeClass("disabled");
            } else {
                setShowConfirmModalDeny(false);
                FlashMessage(t('validationMessage.attestDenied'), 'flash-messageSuccess');
                $("#btnApproveAttest").removeClass("disabled");
                $(`#${apiResponseAttest.id}`).removeClass("disabled");
            }

            setTimeout(() => {
                CustomReload();
            }, 2000);
        }
    }, [apiResponseAttest2]);

    const handleChangeAttest = () => {
        setApiRequestAcceptAttest((prevFormData) => ({
            ...prevFormData,
            id: apiResponseAttest.id,
            attested: true,
        }))
    }

    // HANDLE DENY
    const handleChangeAttestDenyId = (id) => {
        setApiRequestDenyAttest((prevFormData) => ({
            ...prevFormData,
            id: id,
        }))
    }

    // HANDLE DENY REASON
    const handleChangeAttestDenyReason = (e) => {
        if (e && e.target && e.target.value && e.target.value !== "") {
            setApiRequestDenyAttest((prevFormData) => ({
                ...prevFormData,
                reason: e.target.value,
            }))
            setErrorReason(false);
        } else {
            setErrorReason(true);
        }
    }

    // ERROR MESSAGES
    useEffect(() => {
        if (errorMessage !== '') {
            if (errorMessage.includes("{")) {
                FlashMessage(<FlashMessageInsufficientAccountAmount jsonString={errorMessage} />, 'flash-messageError', 10000);
            }
            else {
                if (errorMessage === 'InsufficientBalance') {
                    FlashMessage(t('validationMessage.insufficientBalance'), 'flash-messageError');
                }
                if (errorMessage === 'UserNeedBankAccount') {
                    FlashMessage(t('validationMessage.notAccount'), 'flash-messageError');
                }
            }
            setTimeout(() => {
                $("#btnApproveAttest").removeClass("disabled");
                $(`#${apiResponseAttest.id}`).removeClass("disabled");
            }, errorMessage.includes("{") ? 10000 : 2000);
            setErrorMessage('');
        }
    }, [errorMessage]);

    return (
        <>
            <Col lg={1}>
                <CustomButton
                    text={t('button.back')}
                    icon={'bx bx-chevron-left'}
                    buttonSize={'small'}
                    customClasses={'ms-3 mb-3'}
                    onClick={() => {
                        navigate(fromValue === "history" ? ADMIN_PATHS.ATTEST_LIST_HISTORY : (fromValue === "primary" ? ADMIN_PATHS.ATTEST_LIST_PENDING_PRIMARY : ADMIN_PATHS.ATTEST_LIST_PENDING))
                    }}
                />
            </Col>
            {(apiResponseAttest !== null && apiResponseAttest.id !== '') ?
                <AttestViews data={apiResponseAttest} onChange={handleChangeAttest} onValidate={handleValidation} onChangeDenyId={handleChangeAttestDenyId} onchangeDenyReason={handleChangeAttestDenyReason} setErrorReason={setErrorReason} errorReason={errorReason} setShowConfirmModalApprove={setShowConfirmModalApprove} showConfirmModalApprove={showConfirmModalApprove} setShowConfirmModalDeny={setShowConfirmModalDeny} showConfirmModalDeny={showConfirmModalDeny} />
                : ''}

        </>
    );

}