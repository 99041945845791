import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { Button, Col, Row, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import LogoLRF from "../../../AppSystem/assets/img/LRF_Logga.svg";
import { useEffect } from "react";
import { API_URL, USER_PATHS } from "../../../config";
import axios from "axios";
import { SaveRedux } from "../../../Common/Helpers/SaveRedux";
import { useDispatch } from "react-redux";


const LoginWithEntraID = () => {
    const { instance } = useMsal();
    const { t } = useTranslation();
    const dispatch = useDispatch(); 
    const login =  async() => {

        await instance.initialize();
        await instance.loginRedirect({
            ...loginRequest,
            prompt: 'select_account'
        })
        .catch(e => {console.log('Error during login:', e)});
    };

    useEffect(() => { 
        const handleRedirectResponse = async() => {
            instance.handleRedirectPromise().then(response => {
              if (response) {
                const accessToken = response.accessToken;
                fetchData(accessToken);
              }
            }).catch(error => {
              console.error('Error handling redirect:', error);
            });
          };
    
          setTimeout(() => {handleRedirectResponse();},3000);
           
    }, [instance]);

   

    const fetchData = async (accessToken) => {
        try {
            const config = {
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}`,
                },
              };

            const axiosCall = await axios.post(`${API_URL}/entraid`,{}, config);
            if (axiosCall && axiosCall.data) {
                const response =  axiosCall;
                SaveRedux(dispatch,'tokenData', response.data.token);
                SaveRedux(dispatch,'refreshTokenData', response.data.refreshToken);
                SaveRedux(dispatch,'userData', response.data.user);
                SaveRedux(dispatch,'rolesData', response.data.roles);
                SaveRedux(dispatch,'orgData', response.data.orgData);
                const link = document.createElement('a');
                link.href = USER_PATHS.APP;
                link.rel = 'noopener noreferrer';
                link.click();
            }
                               
        } catch (error) {
          console.log('error', error);
        }
    };

    const handleLogout = async () => {
        try {
          await instance.logoutRedirect();  
        } catch (error) {
          console.error('Error durante el logout:', error);
        }
    };

    return (
        <>
            <div className="container-fluid vh-100 bg-cream">
                <AuthenticatedTemplate>
                    <Row className="vh-100 d-flex align-items-center">
                        <Col  className="bg-white mx-auto rounded-4" style={{ overflow: 'hidden' }}>
                            <Row>                        
                                <>                     
                                    <Col xs={11} md="5" className=" d-flex justify-content-center align-items-center py-5 login-test fade-in z-20">
                                        <img src={LogoLRF} className="w-75  " />
                                    </Col>                         
                                    <Col  className='py-5'>
                                        <Row>
                                            <div className="spinner-container">
                                                <div className="spinner-wrapper">
                                                    <Spinner color='success' style={{ width: '5rem', height: '5rem' }} />
                                                    <p>{t('roterLogin.spinner')}</p>
                                                </div>
                                                {/* <Col className="text-center py-5 z-3">                                                  
                                                 <Button className="box-green border-0"  onClick={async () => {handleLogout();}} >Loggaout</Button>
                                                </Col> */}
                                            </div>
                                        </Row>
                                    </Col>
                                </>     
                            </Row>
                        </Col>
                    </Row>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                <Row className="vh-100 d-flex align-items-center">
                        <Col  className="bg-white mx-auto rounded-4" style={{ overflow: 'hidden' }}>
                            <Row>                        
                                <>                     
                                    <Col xs={11} md="5" className=" d-flex justify-content-center align-items-center py-5 login-test fade-in z-20">
                                        <img src={LogoLRF} className="w-75  " />
                                    </Col>                         
                                    <Col  className='py-5'>
                                        <Row>
                                            <Col lg="12" className="text-center z-3">
                                                <h3 className="py-2 text-green fw-bold">Inloggning</h3>
                                            </Col>
                                            <Col className="text-center py-5 z-3">                                                  
                                                <Button className="box-green border-0"  onClick={async () => {login();}} >Logga in med O365</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </>     
                            </Row>
                        </Col>
                    </Row>
                </UnauthenticatedTemplate>
            </div>
        </>
      );
    };
    
    export default LoginWithEntraID;