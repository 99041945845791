import {useEffect, useState} from "react";
import axios from 'axios';
import {useDispatch, useSelector} from "react-redux";
import { SendMessageToServerSignalR } from '../../AppSystem/Helpers/SignalRClient';
import { CheckExpired } from '../../AppSystem/Helpers/CheckJwtExpired';
import { SaveLocalStorage } from './SaveLocalStorage';
import { SaveRedux } from './SaveRedux';
import { eventHandler } from '../../AppSystem/Helpers/eventHandler';
import { API_URL, PUBLIC_PATHS } from '../../config';
import i18next from '../../AppSystem/i18n';
import { logoutUser } from "../../AppSystem/Redux/Auth/AuthSlice";
import { CustomReload } from "../../AppSystem/Helpers/CustomReload";
import {jwtDecode} from 'jwt-decode'
import { redirectWhenCloseSession } from "./RedirectWhenCloseSession";
import { useMsal } from "@azure/msal-react";

/*
######## Structure:
apiCallOptions = {
  "method" : "get / post / put / delete",
  "url" : "test/...",
  "data" : { ... },     // Json structure. Optional
  "dataTypes" : "json / file", // Optional    DEFAULT: "json"
  "responseType" : "json / blob", // Optional    DEFAULT: "json"
}

######## Usage:
## Note: you can use await in Apicall depending on if you need the call to be asynchronous

const { ApiCall } = useApiCallService();
const [apiResponse, setApiResponse] = useState(null);

const Test = async () => {
  const apiCallOptions = {
    method: "post",
    url: "/test",
  };

  try {
    await ApiCall(apiCallOptions, setApiResponse);
  } catch (error) {
    // console.log(error);
  }
};

if(apiResponse){
  ....
}


######## Usage with UseEffect:
## Note: you can use await in Apicall depending if you need the call to be asynchronous

const { ApiCall } = useApiCallService();
const [apiResponse, setApiResponse] = useState(null);

 useEffect(() => {

    const handleApiResponse  = (apiResponse) => {

      setApiResponse(apiResponse);
      
    };
    const Test = async () => {
      try {
                 
        const apiCallOptions = {
          method: "get",
          url: "/test",
        };

       await ApiCall(apiCallOptions, handleApiResponse );
       
      } catch (error) {
        console.log('error', error);
      }
      
    };   
    
    Test();

  }, []);


######## Usage to upload a file:
## Note: you can use await in Apicall depending if you need the call to be asynchronous 

  const { ApiCall } = useApiCallService();
  const [apiResponse, setApiResponse] = useState(null);
  const fileInputRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleUpload  = () => {
    setSuccessMessage(null);
    setSuccessMessage(null);
    const selectedFile = fileInputRef.current.files[0];

    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);

      const apiCallOptions = {
        method: "post",
        url: "/file",
        data: formData,
        dataTypes: "file"
      };
  
      try {
        ApiCall(apiCallOptions, setApiResponse); 
        setSuccessMessage('File uploaded successfully.'); 
      } catch (error) {
        setErrorMessage('An error occurred while uploading the file.');
      }
    } else {
      setErrorMessage('Please select a file before uploading it.');
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>        
        <div className="mt-5">
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleUpload}
          />
          <Button onClick={handleClick}>Select and Upload file</Button>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        </div> 
    </>
  );
*/
const useApiCallService = () => {
    const dispatch = useDispatch();
    const { instance } = useMsal();
    const {userData, tokenData, refreshTokenData} = useSelector(state => state.auth);
    let userId = '';

  const ApiCall = async (apiCallOptions, setApiResponse, setErrorMessage = null) => {

    if (refreshTokenData !== '' && refreshTokenData?.expires != null && CheckExpired('refreshToken', refreshTokenData?.expires) === true) {
      const decodedToken = jwtDecode(tokenData);
      const loginMethod = decodedToken.loginMethod;
      
      if(loginMethod && loginMethod.toLowerCase() === 'lrfssoentraid'){
        dispatch(logoutUser());
        await instance.logoutRedirect();
        
      }else{
        dispatch(logoutUser());
        redirectWhenCloseSession(tokenData);
      }
    }

    // SIGNALR
    async function waitForMessage() {
      return new Promise((resolve, reject) => {
        const timeoutDuration = 10000;
        const timeout = setTimeout(() => {
          eventHandler.off('ProcessUpdates');
          reject(new Error('Timeout waiting for message'));
        }, timeoutDuration);

        eventHandler.once('ProcessUpdates', response => {       
          clearTimeout(timeout);
          resolve(response);
        });

        SendMessageToServerSignalR(refreshTokenData.id, userId, dispatch);
      });
    } 

    if(apiCallOptions.url.includes('/org/select-org') && apiCallOptions.method.toLowerCase() === 'post'){
      
      try {
        userId = userData.id;
        await waitForMessage();
    } catch (error) {
        console.log('Error al introducir la connection id:', error);
        return; 
    }
    }

    // CONFIG AXIOS
    const apiUrl = API_URL + apiCallOptions.url;
    let contentType = 'application/json';
    if (apiCallOptions.dataTypes !== null && apiCallOptions.dataTypes === 'file') {
      contentType = 'multipart/form-Data';
    }
    if (apiCallOptions.dataTypes !== null && apiCallOptions.dataTypes === 'blob') {
      contentType = apiCallOptions.dataTypes;
    }
    const responseType = apiCallOptions.responseType !== null ? apiCallOptions.responseType : 'json';
    const config = {
      responseType,
      withCredentials: true,
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${tokenData}`,
        'Accept-Language': i18next.language || 'en-US'
      },
    };

    // CHECK TOKEN AND JWT EXPIRED
    let expiredJwt = false;   
    if (tokenData !== '' && refreshTokenData !== '') {
      const expired = CheckExpired('refreshToken', refreshTokenData?.expires);
      if (expired === true) {
        SaveLocalStorage('LogoutUser', true);
        return null;
      } 

        expiredJwt = CheckExpired('jwt', tokenData);
        if (expiredJwt) {
          try {
              const response = await waitForMessage();
              if (response.isSuccess === true) {
                  const token = response.token;
                  const refreshToken = response.refreshToken;
                  // SAVE REDUX
                  SaveRedux(dispatch, 'tokenData', token);
                  SaveRedux(dispatch, 'refreshTokenData', refreshToken);
                  const newConfig = {
                      responseType,
                      withCredentials: true,
                      headers: {
                          'Content-Type': contentType,
                          Authorization: `Bearer ${token}`,
                          'Accept-Language': i18next.language || 'en-US'
                      },
                  };
                  Object.assign(config, newConfig);
              } else {
                  console.log('errorMessage', response.errorMessage);
                  return;
              }
          } catch (error) {
              console.log('Error al obtener nuevo token:', error);
              return; 
          }
      }
    }
    // AXIOS CALL
    let axiosCall;
    switch (apiCallOptions.method.toLowerCase()) {
      case 'get':
        axiosCall = axios.get(apiUrl, config);
        break;
      case 'post':
        axiosCall = axios.post(apiUrl, apiCallOptions.data, config);
        break;
      case 'put':
        axiosCall = axios.put(apiUrl, apiCallOptions.data, config);
        break;
      case 'delete':
        const deleteData = {
          data: apiCallOptions.data, ...config
        }
        axiosCall = axios.delete(apiUrl, deleteData);
        break;
      default:
        break;
    }

    // TRY AXIOS CALL
      async function fetchData() {
        try {
          const tempResponse = await axiosCall;  
          setApiResponse(tempResponse.data);
        } catch (error) {
          setApiResponse(null);
          if (setErrorMessage !== null) {
              setErrorMessage(error?.response?.data?.title);
          }
        }
      }
      await fetchData();
  }

  return { ApiCall };
};

export default useApiCallService;