import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './AppSystem/Redux/Store';
import { Provider } from 'react-redux';
import i18n from "./AppSystem/i18n";
import {I18nextProvider} from "react-i18next";
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './Auth/Components/EntraID/authConfig';
import { MsalProvider } from '@azure/msal-react';

const msalInstance = new PublicClientApplication(msalConfig);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MsalProvider instance={msalInstance}>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
        {/* <React.StrictMode> */}
          <App />
        {/* </React.StrictMode> */}
        </Provider>
      </I18nextProvider>
    </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
